import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faXTwitter } from '@fortawesome/free-brands-svg-icons'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'




const navigation = [
//   { name: 'Product', href: '#' },
  { name: 'AI Guide', href: 'https://guide.teahouseai.com' },
//   { name: 'Features', href: '#' },
//   { name: 'Company', href: '#' },
]

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="tw-bg-white">
      <header className="tw-absolute tw-inset-x-0 tw-top-0 tw-z-50">
        <nav className="tw-mx-auto tw-flex tw-max-w-7xl tw-items-center tw-justify-between tw-p-6 lg:tw-px-8" aria-label="Global">
          <div className="tw-flex lg:tw-flex-1">
            <a href="#" className="tw--m-1.5 tw-p-1.5">
              <span className="tw-sr-only">Your Company</span>
              <img
                    className="tw-h-12 tw-w-auto" 
                    src="/on-white-teahouse-STACKED.svg"
                    alt="Your Company"
                />
            </a>
          </div>
          <div className="tw-flex lg:tw-hidden">
            <button
              type="button"
              className="tw--m-2.5 tw-inline-flex tw-items-center tw-justify-center tw-rounded-md tw-p-2.5 tw-text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="tw-sr-only">Open main menu</span>
              <Bars3Icon className="tw-h-6 tw-w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="tw-hidden lg:tw-flex lg:tw-gap-x-12">
            {navigation.map((item) => (
              <a 
                key={item.name} 
                href={item.href} 
                className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 hover:tw-text-blue-500"
                target={item.href.includes('https') ? "_blank" : "_self"}
                rel={item.href.includes('https') ? "noopener noreferrer" : ""}
              >
                {item.name}
              </a>
            ))}
            <a href="https://bit.ly/ucteatime" target="_blank" rel="noopener noreferrer" >
              <FontAwesomeIcon icon={faDiscord} className="tw-inline-block hover:tw-text-indigo-500" />
            </a>
            {/* <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faXTwitter} className="tw-inline-block hover:tw-text-blue-500" />
            </a> */}
          </div>
          {/* <div className="tw-hidden lg:tw-flex lg:tw-flex-1 lg:tw-justify-end">
            <a href="#" className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">
              Log in <span aria-hidden="true">&rarr;</span>
            </a>
          </div> */}
        </nav>
        <Dialog as="div" className="lg:tw-hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="tw-fixed tw-inset-0 tw-z-50" />
          <Dialog.Panel className="tw-fixed tw-inset-y-0 tw-right-0 tw-z-50 tw-w-full tw-overflow-y-auto tw-bg-white tw-px-6 tw-py-6 sm:tw-max-w-sm sm:tw-ring-1 sm:tw-ring-gray-900/10">
            <div className="tw-flex tw-items-center tw-justify-between">
              <a href="#" className="tw--m-1.5 tw-p-1.5">
                <span className="tw-sr-only">TeaHouseAI</span>
                <img
                    className="tw-h-20 tw-w-auto tw-pt-5" 
                    src="/on-white-teahouse-STACKED.svg"
                    alt="Your Company"
                />
              </a>
              <button
                type="button"
                className="tw--m-2.5 tw-rounded-md tw-p-2.5 tw-text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="tw-sr-only">Close menu</span>
                <XMarkIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="tw-mt-6 tw-flow-root">
              <div className="tw--my-6 tw-divide-y tw-divide-gray-500/10">
                <div className="tw-space-y-2 tw-py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="tw--mx-3 tw-text-2xl tw-h-16 tw-block tw-rounded-lg tw-px-3 tw-py-2 tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900 hover:tw-bg-gray-50"
                      target={item.href.includes('https') ? "_blank" : "_self"}
                      rel={item.href.includes('https') ? "noopener noreferrer" : ""}
                    >
                      {item.name}
                    </a>
                  ))}
                  <a href="https://discord.com" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faDiscord} className="tw-inline-block tw-text-2xl" />
                  </a>
                  {/* <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faXTwitter} />
                  </a> */}
                </div>
                {/* <div className="tw-py-6">
                  <a
                    href="#"
                    className="tw--mx-3 tw-block tw-rounded-lg tw-px-3 tw-py-2.5 tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900 hover:tw-bg-gray-50"
                  >
                    Log in
                  </a>
                </div> */}
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      <main>
        <div className="tw-relative tw-isolate">
          {/* <svg
            className="tw-absolute tw-inset-x-0 tw-top-0 tw--z-10 tw-h-[64rem] tw-w-full tw-stroke-gray-200 [mask-image:tw-radial-gradient(32rem_32rem_at_center,tw-white,tw-transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="tw-overflow-visible tw-fill-gray-50">
              <path
                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect width="100%" height="100%" strokeWidth={0} fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
          </svg> */}
          <div
            className="tw-absolute tw-left-1/2 tw-right-0 tw-top-0 tw--z-10 tw--ml-24 tw-transform-gpu tw-overflow-hidden tw-blur-3xl lg:tw-ml-24 tw-xl:ml-48"
            aria-hidden="true"
          >
            <div
              className="tw-aspect-[801/1036] tw-w-[50.0625rem] tw-bg-gradient-to-tr tw-from-[#ff80b5] tw-to-[#9089fc] tw-opacity-30"
              style={{
                clipPath:
                  'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
              }}
            />
          </div>
          <div className="tw-overflow-hidden">
            <div className="tw-mx-auto tw-max-w-7xl tw-px-6 tw-pb-4 tw-pt-36 sm:tw-pt-60 lg:tw-px-8 lg:tw-pt-28">
              <div className="tw-mx-auto tw-max-w-2xl tw-gap-x-14 lg:tw-mx-0 lg:tw-flex lg:tw-max-w-none lg:tw-items-center">
                <div className="tw-relative tw-w-full tw-max-w-xl lg:tw-shrink-0 tw-xl:max-w-2xl">
                  <h1 className="tw-text-4xl tw-font-bold tw-tracking-tight tw-text-gray-900 sm:tw-text-6xl lg:tw-pt-8">
                    TeahouseAI
                  </h1>
                  <p className="tw-mt-6 tw-text-lg tw-leading-8 tw-text-gray-600 sm:tw-max-w-md lg:tw-max-w-none">
                    Changing the way people connect.
                  </p>
                  <div className="tw-mt-10 tw-flex tw-items-center tw-gap-x-6">
                    {/* <a
                      href="#"
                      className="tw-rounded-md tw-bg-indigo-600 tw-px-3.5 tw-py-2.5 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-indigo-500 tw-focus-visible:outline tw-focus-visible:outline-2 tw-focus-visible:outline-offset-2 tw-focus-visible:outline-indigo-600"
                    >
                      Get started
                    </a> */}

                    {/* <a href="#" className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">
                      Live demo <span aria-hidden="true">→</span>
                    </a> */}
                  </div>
                </div>
                <div className="tw-mt-14 tw-flex tw-justify-end tw-gap-8 sm:tw--mt-44 sm:tw-justify-start sm:tw-pl-20 lg:tw-mt-0 lg:tw-pl-0">
                  <div className="tw-ml-auto tw-w-44 tw-flex-none tw-space-y-8 tw-pt-32 sm:tw-ml-0 sm:tw-pt-80 lg:tw-order-last lg:tw-pt-36 tw-xl:order-none tw-xl:pt-80">
                    <div className="tw-relative">
                      <img
                        src="https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
                        alt=""
                        className="tw-aspect-[2/3] tw-w-full tw-rounded-xl tw-bg-gray-900/5 tw-object-cover tw-shadow-lg"
                      />
                      <div className="tw-pointer-events-none tw-absolute tw-inset-0 tw-rounded-xl tw-ring-1 tw-ring-inset tw-ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="tw-mr-auto tw-w-44 tw-flex-none tw-space-y-8 sm:tw-mr-0 sm:tw-pt-52 lg:tw-pt-36">
                    <div className="tw-relative">
                      <img
                        src="https://images.unsplash.com/photo-1485217988980-11786ced9454?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
                        alt=""
                        className="tw-aspect-[2/3] tw-w-full tw-rounded-xl tw-bg-gray-900/5 tw-object-cover tw-shadow-lg"
                      />
                      <div className="tw-pointer-events-none tw-absolute tw-inset-0 tw-rounded-xl tw-ring-1 tw-ring-inset tw-ring-gray-900/10" />
                    </div>
                    <div className="tw-relative">
                      <img
                        src="https://images.unsplash.com/photo-1517486808906-6ca8b3f04846?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.4&w=396&h=528&q=80"
                        alt=""
                        className="tw-aspect-[2/3] tw-w-full tw-rounded-xl tw-bg-gray-900/5 tw-object-cover tw-shadow-lg"
                      />
                      <div className="tw-pointer-events-none tw-absolute tw-inset-0 tw-rounded-xl tw-ring-1 tw-ring-inset tw-ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="tw-w-44 tw-flex-none tw-space-y-8 tw-pt-32 sm:tw-pt-0">
                    <div className="tw-relative">
                      <img
                        src="https://images.unsplash.com/photo-1507537297725-24a1c029d3ca?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=left&w=400&h=528&q=80"
                        alt=""
                        className="tw-aspect-[2/3] tw-w-full tw-rounded-xl tw-bg-gray-900/5 tw-object-cover tw-shadow-lg"
                      />
                      <div className="tw-pointer-events-none tw-absolute tw-inset-0 tw-rounded-xl tw-ring-1 tw-ring-inset tw-ring-gray-900/10" />
                    </div>
                    <div className="tw-relative">
                      <img
                        src="https://images.unsplash.com/photo-1670272505284-8faba1c31f7d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
                        alt=""
                        className="tw-aspect-[2/3] tw-w-full tw-rounded-xl tw-bg-gray-900/5 tw-object-cover tw-shadow-lg"
                      />
                      <div className="tw-pointer-events-none tw-absolute tw-inset-0 tw-rounded-xl tw-ring-1 tw-ring-inset tw-ring-gray-900/10" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
